<template>
  <v-autocomplete
    v-model="valueMutation"
    class="model-select__shop"
    outlined
    clearable
    :rules="rules"
    deletable-chips
    :chips="$attrs.chips != null ? $attrs.chips : true"
    auto-select-first
    hide-selected
    :items="selectList"
    item-value="id"
    item-text="name"
    item-disabled="deleted_at"
    :loading="loadingAction"
    :placeholder="placeholder"
    :multiple="multiple"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  const SESSION_KEY = 'shop-select:shop-id'

  export default {
    name: 'ShopSelect',
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object, Array], default: undefined },
      programId: { type: [Number], default: undefined },
      multiple: { type: Boolean, default: false },
      useSession: { type: Boolean, default: false },
      rules: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        loadingAction: false,
      }
    },
    computed: {
      ...mapGetters({
        globalProgramId: 'programId',
        shopList: 'company/program/shops',
      }),
      programIdList () {
        return this.programId || this.globalProgramId
      },
      placeholder () {
        return this.$tc('label.select_shop', this.multiple ? 2 : 1)
      },
      selectList () {
        return this.shopList 
        //.concat( [{id: -1, name: 'Не указан(а)'} ] ) // TODO for filter
      },
      labelComp () {
        if (this.$attrs.label) return this.$attrs.label
        else return this.valueMutation && (!this.multiple || this.valueMutation.length > 0) ? '' : this.placeholder
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          if (this.useSession) {
            this.$session.set(SESSION_KEY, v)
          }
          this.$emit('update', v)
        },
      },
    },
    watch: {
      programIdList (v) {
        if (this.useSession) this.$session.remove(SESSION_KEY)
        this.init()
      },
    },
    created () {
      this.init()
    },

    methods: {
      ...mapActions({
        getShopList: 'company/program/listShop',
      }),

      remove (item) {
        this.valueMutation = null
      },
      async init () {
        try {
          this.loadingAction = true
          await this.getShopList({ id: this.programIdList })
          if (this.useSession && this.$session.has(SESSION_KEY)) {
            const shopId = this.$session.get(SESSION_KEY)
            const shop = this.selectList.find(item => item.id === Number(shopId))
            if (shop) {
              this.valueMutation = shop.id
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
<style lang="scss" scoped>
.model-select__shop {
  & ::v-deep {
    .v-input__control {
      .v-input__slot {
        box-shadow: none!important;
      }
    }
  }
}
</style>
